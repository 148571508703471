import { render, staticRenderFns } from "./QFormBuilder.vue?vue&type=template&id=150e3fcc&scoped=true&"
import script from "./QFormBuilder.vue?vue&type=script&lang=ts&"
export * from "./QFormBuilder.vue?vue&type=script&lang=ts&"
import style0 from "./QFormBuilder.vue?vue&type=style&index=0&id=150e3fcc&prod&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "150e3fcc",
  null
  
)

export default component.exports
import {QLayout,QDrawer,QTabs,QTab,QTabPanels,QTabPanel,QForm,QBtn,QPageContainer,QPage,QSpace,QIcon,QSeparator,QCardSection,QCard,QBadge,QTooltip,QDialog,QInput,QSelect,QToggle,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QLayout,QDrawer,QTabs,QTab,QTabPanels,QTabPanel,QForm,QBtn,QPageContainer,QPage,QSpace,QIcon,QSeparator,QCardSection,QCard,QBadge,QTooltip,QDialog,QInput,QSelect,QToggle})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
