
import QFormBuilder from './QFormBuilder.vue'
import FormDataTools from "@/components/Mixin/FormDataTools";
import {mapActions, mapState} from "vuex";

export default {
  name: 'BuilderPage',
  components: {QFormBuilder},
  data: function () {
    return {
      formData: {},
      dynamicModule: {},
      loaded: false,
    }
  },
  methods: {
    ...mapActions('dynamic', ['getModuleData', 'saveModuleData', 'deleteModuleData']),
    async load() {
      this.loaded = false;
      const module = this.dynModuleName;
      const tools = new FormDataTools(module);
      await tools.load();
      this.formData = tools.getFormData();
      this.dynamicModule = await this.getModuleData(module);
      this.loaded = true;
      /*

      const model = new AbModel();
      model.setDynModuleName(module);
      await model.getModuleFieldsInfo();



      const fields = model.dynFields();
      const formFields = [];


      const dropDownValues = model.dropDownValues;

      for (const key in fields) {
        const newField = Object.assign(fieldsMapping.defaultFormField(), fields[key]);
        const type = fieldsMapping.getSnakeCaseFromCamelFieldType(newField.type);
        if (!type) {
          this.formData.unSectionsFields.push(Object.assign(fieldsMapping.defaultDbEditableField(), fields[key]));
          continue;
        }
        newField.type = type;
        const dropDownOptions = typeof dropDownValues[key] !== 'undefined' ? dropDownValues[key] : [];
        newField.dropdown = fieldsMapping.formOptionsFromDbArray(dropDownOptions);
        if (type === fieldsMapping.SNAKE_RELATED_FIELD) {
          if (newField.query === undefined) {
            newField.query = [];
          }
          const tmpQuery = [];
          newField.query.forEach(q => {
            tmpQuery.push(Object.assign({...fieldsMapping.defaultNewRelatedToFieldQueryRow()}, q));
          });
          newField.query = tmpQuery;
          const relatedFieldCopyArr = newField.relatedFieldCopy !== undefined ? Object.keys(newField.relatedFieldCopy) : [];
          newField.relatedFieldCopy = fieldsMapping.formOptionsFromDbArray(relatedFieldCopyArr);
        }
        formFields.push(newField);
      }

      const dynSections = model.dynSections();
      const dynSectionsSorted = [];
      const fieldsGenerationsArray = [];
      const listsIndexMapping = [];
      Object
        .keys(dynSections).sort(function (a, b) {
        return dynSections[a].order - dynSections[b].order;
      })
        .forEach(function (key) {
          const currentSection = Object.assign(fieldsMapping.defaultSection(), dynSections[key]);
          currentSection.groups = fieldsMapping.formOptionsFromDbArray(currentSection.groups);
          dynSectionsSorted.push(currentSection);
          fieldsGenerationsArray.push(fieldsMapping.getEmptySections());
          listsIndexMapping[currentSection.order - 1] = currentSection.name;
        });

      formFields.forEach(element => {
        const listIndex = listsIndexMapping.indexOf(element.section);
        if (listIndex < 0) {
          this.formData.unSectionsFields.push(element);
          return false;
        }
        fieldsGenerationsArray[listIndex][fieldsMapping.getStringColumnVal(element)].push(element);
      });

      fieldsGenerationsArray.forEach(tmp => {
        for (const tmpKey in tmp) {
          if (tmp[tmpKey].length) {
            tmp[tmpKey].sort(this.compare).forEach((field, ind) => {
              field.order = ind + 1
            })
          }
        }
      })

      this.formData.sections = dynSectionsSorted;
      this.formData.fields = fieldsGenerationsArray;
      console.log('load', this.formData)

       */
    },
    async save(formData, dynamicModule) {
      const module = this.dynModuleName;
    }
  },
  async mounted() {
    if (this.tenantID) {
      await this.load();
    }
  },
  watch: {
    async tenantID() {
      await this.load();
    }
  },
  computed: {
    ...mapState(["tenantID"]),
    dynModuleName() {
      return this.$route.params.moduleName;
    }
  }
}
