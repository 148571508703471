
import { QInput, QSelect, QToggle } from "quasar";

import EditableDropdownOptions from "./EditableDropdownOptions";
import RelatedFieldOptions from "./RelatedFieldOptions";
import * as fieldsMapping from "../fieldsMapping";

export default {
  name: "EditableElementOptions",
  components: {
    QInput,
    QSelect,
    QToggle,
    EditableDropdownOptions,
    RelatedFieldOptions,
  },
  props: ["value", "typeInfo", "sections", "orderOptions","formData"],
  data() {
    return {};
  },
  methods: {
    ifType(type) {
      switch (type) {
        case "related":
          return this.value.type === fieldsMapping.DB_RELATED_FIELD;
        case "dropdown":
          return this.value.type === fieldsMapping.DB_DROP_DOWN_FIELD;
      }
      return false;
    },
    onSaveDropDownOptions(options){
      this.value.options = options.map((option)=>{
        return option.name
      })
    },
    onSaveRelatedFieldOptions(options) {
      this.value.relatedFieldCopy = options
      this.$emit("onSave", { ...this.value });
    },
    saveCopyToNewForm(options) {
      this.value.copyToNewForm = options
      this.$emit("onSave", { ...this.value });
    },
    saveQuery(options) {
      this.value.query = options
      this.$emit("onSave", { ...this.value });
    },
  },
  computed: {
    sectionsOptions() {
      this.$props.sections.forEach((opt) => {
        opt.value = opt.name;
        return opt;
      });
      return this.$props.sections;
    },
    columnsOptions() {
      return [
        {
          label: fieldsMapping.FIRST_COLUMN_LABEL,
          value: 1,
        },
        {
          label: fieldsMapping.SECOND_COLUMN_LABEL,
          value: 2,
        },
        {
          label: fieldsMapping.FULL_COLUMN_LABEL,
          value: 0,
        },
      ];
    },
    typeOptions() {
      return fieldsMapping.getSourceFieldOptions();
    },
    
  },
  mounted(){
    console.log("Value: ", this.value);
    // console.log('~ this.formData', this.formData)
    // console.log('~ this.value', this.value)
    // console.log('~ this.orderOptions', this.orderOptions)
    // console.log('~ this.sections', this.sections)
    // console.log('~ this.typeInfo', this.typeInfo)
    }
};
