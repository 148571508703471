
  export default {
    name: "EditObject",
    props: ['elementTypes','isModalFormShow','objectForEdit'],
    data() {
      return {
        show: false,
        data: [],
      };
    },
    methods: {
      createdObject() {
        this.data.push({name: 'newField', values: []});
      },
      createdField(ix) {
        this.data[ix].values.push('');
      },
      deleteObject(ix) {
        this.data = [...this.data.slice(0, ix), ...this.data.slice(ix + 1)];
      },
      deleteField(ix, index) {
        this.data[ix].values = [
          ...this.data[ix].values.slice(0, index),
          ...this.data[ix].values.slice(index + 1),
        ];
      },
      saveForm() {
        this.$emit("onSave", { ...this.data });
      },
      cancelForm(){
        this.$emit('onCancel')
      },
      checkValid(val, ix) {
        if (ix) {
          if (Object.keys(this.data[ix].values).length == 0) {
            return "Object not be empty";
          }
        }
        if (!val.length) {
          return "Field not be empty";
        }
      },
    },
    watch: {
      objectForEdit() {
        this.data = []
        if(!this.objectForEdit){
          this.createdObject()
          this.createdField(0)
          return  
        }
          Object.keys(this.objectForEdit).forEach((key) => {
              this.data.push({ name: key, values: this.objectForEdit[key] });
          });
        
      },
      isModalFormShow() {
        this.show = this.isModalFormShow;
      },
    },
  };
