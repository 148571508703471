
import { QBtn } from "quasar";
import { mapState } from "vuex";
import * as fieldsMapping from "../fieldsMapping";
import EditObject from "../forms/EditObject";
import EditArray from "../forms/EditArray";


export default {
  name: "RelatedFieldOptions",
  components: { QBtn, EditObject, EditArray },
  props: ["value"],
  data: () => ({
    columns: [
      {
        name: "field",
        required: true,
        label: "field",
        field: "field",
        sortable: false,
      },
      { name: "order", label: "order", field: "order", sortable: false },
      { name: "value", label: "value", field: "value", sortable: false },
      {
        name: "relatedTo",
        label: "relatedTo",
        field: "relatedTo",
        sortable: false,
      },
    ],
    currentQueryRow: false,
    moduleQueryFieldDropDownOptions: [],
    relatedFieldCopyOptions: [],
    options: null,
    model: null,
    isModalFieldCopy: false,
    isModalFieldNewCopy: false,
    isModalFieldQuery: false,
    fieldQueryTypes: {
      field: "",
      order: 0,
      relatedTo: "",
      value: ""
    },
    fieldCopyTypes: { name: "newField1", values: [] },
    fieldNewCopyTypes: { name: "newField1", values: [] },
    count: 0,
    relatedFieldQuery: [],
    relatedFieldCopy: [],
    relatedFieldNewCopy: [],
    copyToNewForm: []
  }),
  methods: {
    showModalCopy() {
      this.isModalFieldCopy = !this.isModalFieldCopy
    },
    showModalNewCopy() {
      this.isModalFieldNewCopy = !this.isModalFieldNewCopy
    },
    showModalQuery() {
      this.isModalFieldQuery = !this.isModalFieldQuery 
    },
    onSaveObject(data){
      this.relatedFieldCopy = {}
        Object.values(data).forEach((object) => {
          this.relatedFieldCopy[object.name] = object.values
        })
      this.saveRelatedOptions()
      this.isModalFieldCopy = false;
    },
    saveRelatedOptions() {
      this.$emit("onSave", { ...this.relatedFieldCopy });
    },
    onSaveCopyToNewForm(data){
      this.copyToNewForm = {}
        Object.values(data).forEach((object) => {
          this.copyToNewForm[object.name] = object.values
        })
      this.saveCopyToNewForm()
      this.isModalFieldCopy = false;
    },
    saveCopyToNewForm() {
      this.$emit("onSaveCopyToNewForm", { ...this.copyToNewForm });
    },
    onSaveArray(data) {
      this.isModalFieldQuery = false
      this.$emit("onSaveQuery", [...data]);
    },
    onCancelModalForm() {
    // this.relatedFieldCopy = this.value.relatedFieldCopy
    //  this.relatedFieldQuery = this.value.query
      this.isModalFieldCopy = false;
      this.isModalFieldQuery = false;
      this.isModalFieldNewCopy = false
    },

    filterRelatedModule(val, update) {
      update(() => {
        const needle = val.toLocaleLowerCase();
        this.options = this.dynamicModulesOptions.filter(
          (v) => v.toLocaleLowerCase().indexOf(needle) > -1
        );
      });
    },

    changeRelatedModule() {
      this.value.query = [];
      this.value.relatedFieldCopy = [];
       this.isModalFieldNewCopy = []
      this.calcRelatedFieldCopyOptions();
    },
    
    calcRelatedFieldCopyOptions() {
      this.relatedFieldCopyOptions = [];
      if(this.dynamicModules[this.value.relatedModule]){
        const moduleFields = Object.keys(this.dynamicModules[this.value.relatedModule]["fields"]);
      moduleFields.forEach((fieldName) => {
        let alreadyUsed = false;
        for(const key in this.value.relatedFieldCopy){
         if(key === fieldName){
          alreadyUsed = true;
         }
        }
        if (!alreadyUsed) {
          this.relatedFieldCopyOptions.push(fieldName);
        }
      });
      }
      this.relatedFieldCopy = this.value.relatedFieldCopy
      this.relatedFieldNewCopy = this.value.copyToNewForm
      this.relatedFieldQuery = this.value.query
    },
    
    addNewQuery: function () {
      const moduleFieldsOptions = this.calcModuleFieldOptions();
      if (!moduleFieldsOptions.length) {
        return false;
      }
      const index = this.value.query ? this.value.query.length : 0;
      const row = { ...fieldsMapping.defaultNewRelatedToFieldQueryRow() };
      const f = moduleFieldsOptions.shift();
      row.order = index;
      row.value = this.calcRowValue(f);
      row.field = f;
      row.relatedTo = this.calcRelatedToValue(f);
      this.value.query.push(row);
    },
    deleteQueryHandle(queryField) {
      this.value.query.forEach((q, i) => {
        if (q.field === queryField) {
          this.deleteQuery(i);
        }
      });
      this.reCalcQueryOrder();
    },
    calcModuleFieldOptions() {
      // const moduleFields = Object.keys(this.dynamicModules[this.value.relatedModule]['fields']);
      const moduleFields = [];
      for (const fieldName in this.dynamicModules[this.value.relatedModule][
        "fields"
      ]) {
        const fieldType = this.dynamicModules[this.value.relatedModule][
          "fields"
        ][fieldName]["type"];
        if (
          fieldType === fieldsMapping.DB_RELATED_FIELD ||
          fieldType === fieldsMapping.DB_DROP_DOWN_FIELD
        ) {
          moduleFields.push(fieldName);
        }
      }
      for (const q in this.value.query) {
        const ind = moduleFields.indexOf(this.value.query[q]["field"]);
        if (ind > -1) {
          moduleFields.splice(ind, 1);
        }
      }
      return moduleFields;
    },
    deleteQuery(i) {
      this.value.query.splice(i, 1);
    },
    reCalcQueryOrder() {
      this.value.query.forEach((q, ind) => {
        q.order = ind;
      });
    },
    calcRowValue(field) {
      this.calcModuleQueryFieldDropDownOptions(field);
      return this.moduleQueryFieldDropDownOptions.length
        ? this.moduleQueryFieldDropDownOptions[0]
        : "";
    },
    calcRelatedToValue(field) {
      const relatedModuleCurField = this.dynamicModules[
        this.value.relatedModule
      ]["fields"][field];
      return relatedModuleCurField.type === fieldsMapping.DB_RELATED_FIELD
        ? relatedModuleCurField.name
        : "";
    },
    changeQueryField(row) {
      row.value = this.calcRowValue(row.field);
      row.relatedTo = this.calcRelatedToValue(row.field);
    },
    changeQueryOrder(row) {
      this.value.query.forEach((q, ind) => {
        if (q.field === row.field) {
          this.deleteQuery(ind);
          return true;
        }
      });
      this.value.query.splice(row.order, 0, row);
      this.reCalcQueryOrder();
    },
    calcModuleQueryFieldDropDownOptions(field) {
      const relatedModule = this.dynamicModules[this.value.relatedModule];
      const relatedModuleCurField = relatedModule.fields[field];
      this.moduleQueryFieldDropDownOptions =
        relatedModuleCurField.type === fieldsMapping.DB_DROP_DOWN_FIELD &&
        relatedModule.dropDownValues[field] !== undefined
          ? relatedModule.dropDownValues[field]
          : [];
    },
  },
  watch: {
    value() {
      this.relatedFieldCopy = {}
      this.relatedFieldQuery = []
      this.calcRelatedFieldCopyOptions();
    },
  },
  mounted() {
    this.calcRelatedFieldCopyOptions();
    this.options = this.dynamicModulesOptions;
  },
  computed: {
    ...mapState(["dynamicModules"]),
    dynamicModulesOptions() {
      return Object.keys(this.dynamicModules);
    },
    moduleFieldsOption() {
      return this.calcModuleFieldOptions();
    },
    moduleQueryOrderOptions() {
      return this.value.query !== undefined
        ? Object.keys(this.value.query)
        : [];
    },
  },
};
