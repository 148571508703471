
  import { QList, QItem, QBtn } from "quasar";
  export default {
    name: "EditableRadiosOptions",
    components: { QList, QItem, QBtn },
    props: ["value", "dropDownHeader", "readonly", "dropDownListProps"],
    data: () => ({
      newItem: "",
      show: false,
      options: [],
    }),
    methods: {
      addNewItem() {
        this.options.push({ name: this.newItem });
        this.newItem = "";
      },
      deleteItem(index) {
        this.options.splice(index, 1);
      },
      saveItems() {
        this.$emit("onSave", [...this.options]);
      },
    },
    watch: {
      value() {
        this.options = [];
        this.options = this.value.map((option) => {
          return {
            name: option,
          };
        });
      },
    },
    mounted() {
      if (!this.value || !this.value.length) {
        this.addNewItem();
        return;
      } else {
        this.options = this.value.map((option) => {
          return {
            name: option,
          };
        });
      }
    },
  };
