
export default {
  name: "arrayForEdit",
  props: {
    isModalFormShow: null,
    arrayForEdit: null,
    elementTypes: { type: Object },
  },
  data() {
    return {
      show: false,
      errorValid: true,
      editData: [],
    };
  },
  methods: {
    createdField() {
      this.editData.push({ ...this.elementTypes });
    },
    deleteField(inx) {
      this.editData = [
        ...this.editData.slice(0, inx),
        ...this.editData.slice(inx + 1),
      ];
    },
    saveForm() {
      console.log("DataArray: ", this.editData);
      this.$emit("onSave", [...this.editData]);
    },
    cancelForm() {
      this.$emit("onCancel");
    },
    checkValid(val) {
      if (!val.length) {
        return "Field not be empty";
      }
    },
    onSubmit() {
      this.saveForm();
    },
  },
  watch: {
    isModalFormShow() {
      this.show = this.isModalFormShow;
    },
    arrayForEdit() {
      this.editData = [];
      if (!this.arrayForEdit || !this.arrayForEdit.length) {
        this.createdField();
        return;
      }
      this.editData = [...this.arrayForEdit];
      this.editData.forEach((data) => {
        if (!data.value) {
          data.value = "";
        }
        if (!data.relatedTo) {
          data.relatedTo = "";
        }
      });
    },
  },
  mounted() {
    // if (!this.arrayForEdit.length) {
    //   this.createdField();
    // }
  },
};
