import {uid} from "quasar";

export const FIRST_COLUMN_LABEL = 'first';
export const SECOND_COLUMN_LABEL = 'second';
export const FULL_COLUMN_LABEL = 'full';

const DB_TEXT_FIELD = 'text';
const SNAKE_TEXT_FIELD = 'base_field_text';

const DB_DATE_FIELD = 'date';
const SNAKE_DATE_FIELD = 'base_field_date';

const DB_DATE_TIME_FIELD = 'datetime';
const SNAKE_DATE_TIME_FIELD = 'base_field_date_time';

export const DB_RELATED_FIELD = 'relatedField';
export const SNAKE_RELATED_FIELD = 'base_field_related';

const DB_RELATED_MULTI_SELECT_FIELD = 'relatedMultiSelect';
const SNAKE_RELATED_MULTI_SELECT_FIELD = 'base_field_related_multi_select';

export const DB_DROP_DOWN_FIELD = 'dropdown';
export const SNAKE_DROP_DOWN_FIELD = 'base_field_drop_down';

const DB_AUTO_NUMBER_FIELD = 'autoNumber';
const SNAKE_AUTO_NUMBER_FIELD = 'base_field_auto_number';

const DB_CHECKBOX_FIELD = 'checkbox';
const SNAKE_CHECKBOX_FIELD = 'base_field_checkbox';

const DB_CURRENCY_FIELD = 'currency';
const SNAKE_CURRENCY_FIELD = 'base_field_currency';

const DB_DECIMAL_FIELD = 'decimal';
const SNAKE_DECIMAL_FIELD = 'base_field_decimal';

const DB_LARGE_TEXT_FIELD = 'largeText';
const SNAKE_LARGE_TEXT_FIELD = 'base_field_large_text';

const DB_NUMBER_FIELD = 'number';
const SNAKE_NUMBER_FIELD = 'base_field_number';

const DB_PERCENTAGE_FIELD = 'percentage';
const SNAKE_PERCENTAGE_FIELD = 'base_field_percentage';

export function getSnakeCaseFromCamelFieldType(dbFieldType) {
  switch (dbFieldType) {
    case DB_TEXT_FIELD: return SNAKE_TEXT_FIELD;
    case DB_DATE_FIELD: return SNAKE_DATE_FIELD;
    case DB_DATE_TIME_FIELD: return SNAKE_DATE_TIME_FIELD;
    case DB_RELATED_FIELD: return SNAKE_RELATED_FIELD;
    case DB_RELATED_MULTI_SELECT_FIELD: return SNAKE_RELATED_MULTI_SELECT_FIELD;
    case DB_DROP_DOWN_FIELD: return SNAKE_DROP_DOWN_FIELD;
    case DB_AUTO_NUMBER_FIELD: return SNAKE_AUTO_NUMBER_FIELD;
    case DB_CHECKBOX_FIELD: return SNAKE_CHECKBOX_FIELD;
    case DB_CURRENCY_FIELD: return SNAKE_CURRENCY_FIELD;
    case DB_DECIMAL_FIELD: return SNAKE_DECIMAL_FIELD;
    case DB_LARGE_TEXT_FIELD: return SNAKE_LARGE_TEXT_FIELD;
    case DB_NUMBER_FIELD: return SNAKE_NUMBER_FIELD;
    case DB_PERCENTAGE_FIELD: return SNAKE_PERCENTAGE_FIELD;
    default: console.log(dbFieldType); return null;
  }
}

export function getCamelFieldTypeFromSnakeCaseType(formFieldType) {
  switch (formFieldType) {
    case SNAKE_TEXT_FIELD: return DB_TEXT_FIELD;
    case SNAKE_DATE_FIELD: return DB_DATE_FIELD;
    case SNAKE_DATE_TIME_FIELD: return DB_DATE_TIME_FIELD;
    case SNAKE_RELATED_FIELD: return DB_RELATED_FIELD;
    case SNAKE_RELATED_MULTI_SELECT_FIELD: return DB_RELATED_MULTI_SELECT_FIELD;
    case SNAKE_DROP_DOWN_FIELD: return DB_DROP_DOWN_FIELD;
    case SNAKE_AUTO_NUMBER_FIELD: return DB_AUTO_NUMBER_FIELD;
    case SNAKE_CHECKBOX_FIELD: return DB_CHECKBOX_FIELD;
    case SNAKE_CURRENCY_FIELD: return DB_CURRENCY_FIELD;
    case SNAKE_DECIMAL_FIELD: return DB_DECIMAL_FIELD;
    case SNAKE_LARGE_TEXT_FIELD: return DB_LARGE_TEXT_FIELD;
    case SNAKE_NUMBER_FIELD: return DB_NUMBER_FIELD;
    case SNAKE_PERCENTAGE_FIELD: return DB_PERCENTAGE_FIELD;
  }
}

export function getSourceSectionOptions() {
  return [
    {
      value: 'fieldSection',
      type: 'fieldSection',
      icon: 'text_snippet',
      label: 'Fields Section',
      dbFieldType: 'fieldSection',
    },
    {
      value: 'attachments',
      type: 'attachments',
      icon: 'attachment',
      label: 'Attachments',
      dbFieldType: 'attachments',
    },
    {
      value: 'relatedRecords',
      type: 'relatedRecords',
      icon: 'mediation',
      // icon: 'transform',
      label: 'Related Records',
      dbFieldType: 'relatedRecords',
    },
    {
      value: 'media',
      type: 'media',
      icon: 'perm_media',
      label: 'Media Section',
      dbFieldType: 'media',
    },
    {
      value: 'emails',
      type: 'emails',
      icon: 'email',
      label: 'Communication',
      dbFieldType: 'emails',
    },
    {
      value: 'subForm',
      type: 'subForm',
      icon: 'add_to_photos',
      label: 'Subform Section',
      dbFieldType: 'subForm',
    },
    {
      value: 'productLines',
      type: 'productLines',
      icon: 'blur_linear',
      label: 'Product Lines',
      dbFieldType: 'productLines',
    },
  ];
}

export function getSourceFieldOptions() {
  return [
    {
      value: SNAKE_TEXT_FIELD,
      type: SNAKE_TEXT_FIELD,
      icon: 'text_format',
      label: 'Text',
      dbFieldType: DB_TEXT_FIELD,
    },
    // {
    //   value: SNAKE_DATE_FIELD,
    //   type: SNAKE_DATE_FIELD,
    //   icon: 'event',
    //   label: 'Date',
    //   dbFieldType: DB_DATE_FIELD,
    // },
    {
      value: SNAKE_DATE_TIME_FIELD,
      type: SNAKE_DATE_TIME_FIELD,
      icon: 'event',
      label: 'Date time',
      dbFieldType: DB_DATE_TIME_FIELD,
    },
    {
      value: SNAKE_RELATED_FIELD,
      type: SNAKE_RELATED_FIELD,
      icon: 'list',
      label: 'Related',
      dbFieldType: DB_RELATED_FIELD,
    },
    {
      value: SNAKE_RELATED_MULTI_SELECT_FIELD,
      type: SNAKE_RELATED_MULTI_SELECT_FIELD,
      icon: 'playlist_add',
      label: 'RelatedMulti',
      dbFieldType: DB_RELATED_MULTI_SELECT_FIELD,
    },
    {
      value: SNAKE_DROP_DOWN_FIELD,
      type: SNAKE_DROP_DOWN_FIELD,
      icon: 'keyboard_arrow_down',
      label: 'DropDown',
      dbFieldType: DB_DROP_DOWN_FIELD,
    },
    {
      value: SNAKE_AUTO_NUMBER_FIELD,
      type: SNAKE_AUTO_NUMBER_FIELD,
      icon: 'plus_one',
      label: 'AutoNumber',
      dbFieldType: DB_AUTO_NUMBER_FIELD,
    },
    {
      value: SNAKE_CHECKBOX_FIELD,
      type: SNAKE_CHECKBOX_FIELD,
      icon: 'check_box',
      label: 'Checkbox',
      dbFieldType: DB_CHECKBOX_FIELD,
    },
    {
      value: SNAKE_CURRENCY_FIELD,
      type: SNAKE_CURRENCY_FIELD,
      icon: 'euro',
      label: 'Currency',
      dbFieldType: DB_CURRENCY_FIELD,
    },
    {
      value: SNAKE_DECIMAL_FIELD,
      type: SNAKE_DECIMAL_FIELD,
      icon: 'timer_10',
      label: 'Decimal',
      dbFieldType: DB_CURRENCY_FIELD,
    },
    {
      value: SNAKE_LARGE_TEXT_FIELD,
      type: SNAKE_LARGE_TEXT_FIELD,
      icon: 'text_fields',
      label: 'Text area',
      dbFieldType: DB_LARGE_TEXT_FIELD,
    },
    {
      value: SNAKE_NUMBER_FIELD,
      type: SNAKE_NUMBER_FIELD,
      icon: 'looks_one',
      label: 'Number',
      dbFieldType: DB_NUMBER_FIELD,
    },
    {
      value: SNAKE_PERCENTAGE_FIELD,
      type: SNAKE_PERCENTAGE_FIELD,
      icon: 'code',
      label: 'Percentage',
      dbFieldType: DB_PERCENTAGE_FIELD,
    },
  ];
}

export function defaultDbEditableField() {
  return {
    ID: uid(),
    section: null,
    column: null,
    order: null,
    type: SNAKE_TEXT_FIELD,
    label: '',
    name: '',
    mandatory: false,
    mandatoryErrorMessage: '',
    hidden: false,
    defaultValue: '',
    showTooltip: false,
    tooltip: '',
    min: 0,
    minErrorMessage: '',
    max: 0,
    maxErrorMessage: '',
  };
}

export function defaultFormField() {
  const field = Object.assign({}, defaultDbEditableField());
  field.formData = {
    relatedFields: {
      options: []
    },
  }
  field.dropdown = {
    options: []
  }

  field.formData.disabledFields = {};
  field.formData.hiddenFields = {};
  field.formData.dynamicFields = {};
  field.formData.dynamicSections = {};
  field.formData.dynAutoNumbers = {};
  field.formData.dropDownValues = {};

  field.formData.data = {};
  return field;
}

export function getStringColumnVal(searchField) {
  let stringFieldColumn = FIRST_COLUMN_LABEL;
  if (searchField.column === 2) {
    stringFieldColumn = SECOND_COLUMN_LABEL;
  }
  if (!searchField.column || searchField.column == 3) {
    stringFieldColumn = FULL_COLUMN_LABEL;
  }
  return stringFieldColumn;
}

export function getEmptySections()
{
  return {
    first: [],  // FIRST_COLUMN_LABEL
    second: [], // SECOND_COLUMN_LABEL
    full: []    // FULL_COLUMN_LABEL
  };
}

export function defaultSection() {
  return {
    ID: uid(),
    name: '',
    type: '',
    label: '',
    order: null,
    hidden: false,
    icon: '',
    canBeMerge: false,
    groups: [],

    // special for section

    // relatedRecords
    relatedByField: '',
    relatedModule: '',
    // subForm
    subFormField: '',
    //emails
    emailField: '',
    showTemplateEmailForm: {},
    // productLines
    lineType: '', // salesLines', 'invoiceLines', 'generalLines', 'purchaseLines'
  };
}

export function dropdownOptionsToDropdownDbArray(key) {
  const dbArr = [];
  key.options.forEach(option => dbArr.push(option.label));
  return dbArr;
}

export function formOptionsFromDbArray(dbArrayOpt) {
  const formOptions = [];
  dbArrayOpt.forEach(option => formOptions.push({label: option, checked: false}));
  return {options: formOptions};
}

export const defaultNewRelatedToFieldQueryRow = () => ({
  field: '',
  order: 0,
  value: '',
  relatedTo: ''
})