
import {
  QInput,
  QSelect,
  QToggle,
} from 'quasar'

import EditableDropdownOptions from './EditableDropdownOptions'

export default {
  name: 'EditableSectionOptions',
  components: { QInput, QSelect, QToggle, EditableDropdownOptions },
  props: ['value', 'sections'],
  data() {
    return {
      orderOptions: []
    }
  },
  mounted() {
    const options = []
    for (let i = 1; i <= this.sections.length; i++) {
      options.push(i);
    }
    this.orderOptions = options
  },
  methods: {
    ifType (type) {
      return this.value.type === type
    }
  },
  computed: {

  },
}

