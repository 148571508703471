
import {
  QLayout,
  QPageContainer,
  QPage,
  QDrawer,
  QTab,
  QTabs,
  QTabPanel,
  QTabPanels,
  QBtn,
  uid,
  extend,
} from "quasar";

import draggable from "vuedraggable";
import EditableElement from "./editable/EditableElement.vue";
import EditableElementOptions from "./editable/EditableElementOptions.vue";
import EditableSectionOptions from "./editable/EditableSectionOptions.vue";
import EditableModuleOptions from "./editable/EditableModuleOptions.vue";
import EditableDropdownOptions from './editable/EditableDropdownOptions.vue';
import * as fieldsMapping from "./fieldsMapping";

import BaseSectionRelatedRecords from "@/components/Base/BaseSectionRelatedRecords.vue";
import BaseSectionAttachment from "@/components/Base/BaseSectionAttachment.vue";
import BaseSectionMedia from "@/components/Base/BaseSectionMedia.vue";
import BaseSectionSubForm from "@/components/Base/BaseSectionSubForm.vue";
import BaseSectionEmails from "@/components/Base/BaseSectionEmails.vue";

import BaseSectionProductLines from "@/components/Base/BaseSectionProductLines.vue";
import AbModel from "@/components/Models/AbModel";

import Dialog from "@/components/Parts/Dialog.vue";
import store from "@/store";
import db from "@/db";

import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import TextField from "@/components/Dto/Fields/TextField"


@Component({
  components: {
    BaseSectionProductLines,
    EditableElement,
    EditableElementOptions,
    EditableSectionOptions,
    EditableModuleOptions,
    EditableDropdownOptions,
    draggable,
    QLayout,
    QPageContainer,
    QPage,
    QDrawer,
    QTab,
    QTabs,
    QTabPanel,
    QTabPanels,
    // QTooltip,
    QBtn,
    Dialog,
    BaseSectionEmails,
    BaseSectionSubForm,
    BaseSectionRelatedRecords,
    BaseSectionAttachment,
    BaseSectionMedia
  }
})

export default class QFormBuilder extends Vue {
  @Prop() navPosition: string;
  @Prop() fieldIdName: any;
  @Prop() formData: any;
  @Prop() dynamicModule: any;
  @Prop() moduleName: string;
  drawer = true;
  tab = "add";
  currentField = false;
  isDragAction = false;
  isClickForCurrentField = false;
  hovered = [];
  currentSection = false;
  isNewSectionSelect = false;
  selectedSectionName = null;
  first = fieldsMapping.FIRST_COLUMN_LABEL;
  second = fieldsMapping.SECOND_COLUMN_LABEL;
  full = fieldsMapping.FULL_COLUMN_LABEL;
  currentListLength = 0;
  sourceFields = fieldsMapping.getSourceFieldOptions();
  sourceSections = fieldsMapping.getSourceSectionOptions();
  countNewFields = 1;
  showDraggable = true;
  fieldClicked = false;
  createSectionDialog = false;
  newSection = null;

  mounted() {
    this.isNewSectionSelect = true;
    this.currentSection = this.sections.length ? this.sections[0] : false;
  }

  ifType (type) {
    return this.newSection.type === type
  }
  
  goToSettingPage() {
    if (confirm("Are yuo sure?")) {
      this.$router.push({path: "/settings/admin/formBuilder"});
    }
  }
  
  async saveFormData() {
    // this.$emit('onSaveFormData', this.formData, this.dynamicModule, this.moduleName);

    const model = new AbModel();
    model.setDynModuleName(this.moduleName);
    await model.getModuleFieldsInfo();
    console.log("Model: ", model);

    const previouslyFieldsState = model.dynFields();

    const moduleData = Object.assign({}, this.dynamicModule);
    moduleData.dropDownValues = {};
    moduleData.fields = {};
    moduleData.sections = {};
    for (const s in this.sections) {
      const section = this.sections[s];
      moduleData.sections[section.name] = section
    }

    this.fields.forEach((sectionsFields) => {
      for (const sectionColumn in sectionsFields) {
        sectionsFields[sectionColumn].forEach((formField) => {
          const dbField = Object.assign(
            fieldsMapping.defaultDbEditableField(),
            formField
          );
          dbField.max = Number(dbField.max);
          dbField.min = Number(dbField.min);
          if(dbField.type !== 'number' && dbField.type !== 'currency' && dbField.type !== 'decimal') {
            delete dbField.max
            delete dbField.maxErrorMessage
            delete dbField.min
            delete dbField.minErrorMessage
          }
          const fieldName = dbField.name;
          if (formField.options && formField.options.length) {
            moduleData.dropDownValues[fieldName] = formField.options
            delete dbField.options
          }
          delete dbField.dropdown;
          if (dbField.query && dbField.query.length > 0) {
            dbField.query.forEach((q) => {
              if (!q.value) {
                delete q.value;
              }
              if (!q.relatedTo) {
                delete q.relatedTo;
              }
            });
            if (!dbField.query.length) {
              delete dbField.query;
            }
          }
          if (dbField.relatedFieldCopy !== undefined) {
            const dbRelatedFieldCopy = {};
            for (const relatedField in dbField.relatedFieldCopy) {
              dbRelatedFieldCopy[relatedField] = dbField.relatedFieldCopy[relatedField];
            }
            delete dbField.relatedFieldCopy;
            dbField.relatedFieldCopy = dbRelatedFieldCopy;
          }
          delete dbField.formData;
          if(fieldsMapping.getCamelFieldTypeFromSnakeCaseType(formField.type)) {
            dbField.type = fieldsMapping.getCamelFieldTypeFromSnakeCaseType(formField.type);
          } else {
            dbField.type = formField.type;
          }
          const previouslyFieldState = previouslyFieldsState[fieldName] !== undefined ? previouslyFieldsState[fieldName] : {};
          if(dbField.type === 'text') {
            const textField: TextField = dbField;
            moduleData.fields[fieldName] = textField
          } else {
            moduleData.fields[fieldName] = Object.assign(
              previouslyFieldState,
              dbField
            );
          }
        });
      }
    });

    if(this.formData && this.formData.unSectionsFields && this.formData.unSectionsFields.length > 0){
      this.formData.unSectionsFields.forEach((unSectionField) => {
        moduleData.fields[unSectionField.name] = unSectionField;
      });
    }
    this.sections.forEach((section) => {
      moduleData.sections[section.name] = Object.assign({}, section);
      if(section.groups && section.groups.length > 0) {
        moduleData.sections[section.name]["groups"] = fieldsMapping.dropdownOptionsToDropdownDbArray(section.groups);
      }
    });
    console.log("ModuleData: ", moduleData);
    try {
      const table = "tenants/" + store.state.tenantID + "/modules/";
      db.collection(table)
        .doc(this.moduleName)
        .update(moduleData)
        .then(() => {
          this.$store.dispatch("showAlertMessage", "update");
        })
        .catch(() => {
          this.$store.dispatch("showAlertMessage", "error");
        });
    } catch (e) {
      this.$store.dispatch("showAlertMessage", "error");
    }
  }

  onSaveElementOptions(value) {
    this.formData.dynamicFields[value.name] = value
  }

  deleteSection(sIdx) {
    if (confirm("Are you sure?")) {
      this.showDraggable = false
      this.sections.splice(sIdx, 1);
      this.fields.splice(sIdx, 1);
      this.showDraggable = true
    }
  }

  createField(item) {
    const newField = fieldsMapping.defaultFormField();
    newField.type = item.type;
    newField.label = item.label;
    if (newField.type === fieldsMapping.SNAKE_RELATED_FIELD) {
      newField.query = [];
    }
    newField.name = item.label.toLowerCase()
    Object.keys(this.dynamicModule.fields).forEach((key) => {
      if(key === newField.name){
        newField.name = item.label.toLowerCase() + this.countNewFields;
        this.countNewFields++
      }
    })
    newField.type = item.dbFieldType
    return newField;
  }

  deleteField(idx, sIdx, column) {
    const name = this.fields[sIdx][column][idx].name
    this.currentField = false;
    this.tab = "add";
    delete this.dynamicModule.fields[name]
    this.$delete(this.fields[sIdx][column], idx);
    this.fields[sIdx][column] = this.fields[sIdx][column].map(
      (field, ind) => {
        field.order = ind + 1;
        return field;
      }
    );
  }

  duplicateField(idx, sIdx, column) {
    const newField = extend(true, {}, this.fields[sIdx][column][idx]);
    newField['ID'] = uid();
    newField['name'] = newField['name'] + '[Duplicate]'
    newField['label'] = newField['label'] + '[Duplicate]'
    delete newField[this.fieldIdName];
    this.updateList(newField, column, false);
    this.selectForEdit(newField, this.fields[sIdx][column], false);

  }

  onChangeSection(evt) {
    this.showDraggable = false
    if (evt.moved) {
      this.cardClick(evt.moved.element.name);
      // this.sections = 
      this.sections.map((field, ind) => {
        field.order = ind + 1;
        return field;
      });

      const newSIdx = evt.moved.newIndex;
      const oldSIdx = evt.moved.oldIndex;

      const newSectionFields = this.fields[newSIdx];
      this.fields[newSIdx] = this.fields[oldSIdx];
      this.fields[oldSIdx] = newSectionFields;
    }
      this.showDraggable = true
  }

  onChange(evt, sIdx, column) {
    //where
    this.isDragAction = true;
    if (evt.added) {
      // evt.added.element - from
      const fromCol = evt.added.element.column;
      const fromSecIdx = this.getSectionIdxFromField(evt.added.element);

      const sectionName = this.sections[sIdx]["name"];
      evt.added.element.section = sectionName;
      evt.added.element.column = column;
      evt.added.element.order = evt.added.newIndex + 1;
      this.fields[sIdx][
        this.getStringColumnVal({column: column})
        ] = this.fields[sIdx][this.getStringColumnVal({column: column})].map(
        (field, ind) => {
          field.order = ind + 1;
          field.section = sectionName;
          field.column = column;
          return field;
        }
      );

      if (fromSecIdx) {
        this.fields[fromSecIdx][
          this.getStringColumnVal({column: fromCol})
          ] = this.fields[fromSecIdx][
          this.getStringColumnVal({column: fromCol})
          ].map((field, ind) => {
          field.order = ind + 1;
          return field;
        });
      }

      this.selectForEdit(
        evt.added.element,
        this.fields[sIdx][this.getStringColumnVal(evt.added.element)],
        false
      );
    }
    if (evt.moved) {
    const columnName = this.getStringColumnVal({column: column})
      this.fields[sIdx][columnName] = this.fields[sIdx][columnName].map(
        (field, ind) => {
          field.order = ind + 1;
          return field;
        }
      );

      this.selectForEdit(
        evt.moved.element,
        this.fields[sIdx][this.getStringColumnVal(evt.moved.element)],
        false
      );
    }
    setTimeout(
      function (me) {
        me.isDragAction = false;
      },
      500,
      this
    );
  }

  onAddFieldClick(sourceField) {
    const field = this.createField(sourceField);
    field.column = 1;
    field.section = this.currentSection['name'];
    this.updateList(field, this.first, true);
  }

  onAddSectionClick(sec) {
    this.newSection = {}
    this.newSection.label = sec.label
    this.newSection.type = sec.type
    this.newSection.hidden = false
    this.newSection.canBeMerge = false
    
    this.createSectionDialog = true
    // this.createSection(sec)
    this.isClickForCurrentField = true;
  }

  createSection(){
    this.showDraggable = false
    const sectionOrder = this.sections.length ? this.sections.slice(-1)[0]["order"] + 1 : 1;
    const newSection = fieldsMapping.defaultSection();
    newSection.order = sectionOrder;
    newSection.name = this.newSection.name;
    newSection.label = this.newSection.label;
    newSection.type = this.newSection.type;
    newSection.groups['options'] = newSection.groups;
    for(const section in this.sections) {
      if(!this.fields[section]) {
        this.fields.push(fieldsMapping.getEmptySections())
      }
    }
    this.fields.push(fieldsMapping.getEmptySections());
    this.sections.push(newSection);
    this.showDraggable = true
  }

  selectForEdit(field, currentList, isClickForCurrentField) {
    this.isClickForCurrentField = isClickForCurrentField;
    this.currentListLength = currentList.length;
    this.currentField = field;
    this.tab = "edit";
    this.fieldClicked = true;
    this.resetClickOnField();
  }

  isSelectedForEdit(idx, sIdx, block) {
    return this.currentField['ID'] === this.fields[sIdx][block][idx].ID;
  }

  cardClick(name) {
    this.sections.forEach((section) => {
      if (section.name === name) {
        this.currentSection = section;
        this.isNewSectionSelect = true;
        console.log(name)
        if(!this.fieldClicked){
          this.tab = 'edit-section'
        }
        this.fieldClicked = false;
      }
    });
    console.log("Tab: " + this.tab);
  }

  resetClickOnField() {
    setTimeout(
      function (me) {
        me.isClickForCurrentField = false;
      },
      200,
      this
    );
  }

  updateList(field, columnName, isClickForCurrentField) {
    const sIdx = this.getSectionIdxFromField(field);
    field.order = this.fields[sIdx][columnName].length + 1;
    this.dynamicModule.fields[field.name] = field
    this.fields[sIdx][columnName].push(field);
    this.selectForEdit(
      field,
      this.fields[sIdx][columnName],
      isClickForCurrentField
    );
  }

  getStringColumnVal(searchField) {
    return fieldsMapping.getStringColumnVal(searchField);
  }

  deleteFieldFromList(sectionName, columnInt, beforeUpdatedField) {
    const columnString = this.getStringColumnVal({column: columnInt});
    const sIdx = this.getSectionIdxFromField(beforeUpdatedField);
    this.fields[sIdx][columnString].forEach((f, index) => {
      if (JSON.stringify(f) === JSON.stringify(beforeUpdatedField)) {
        this.deleteField(index, sIdx, columnString);
      }
    });
  }

  emulateDrag(field) {
    const columnName = this.getStringColumnVal(field);
    const sIdx = this.getSectionIdxFromField(field);
    this.selectForEdit(field, this.fields[sIdx][columnName], false);
    this.updateList(field, columnName, false);
  }

  getSectionIdxFromField(field) {
    let sIdx = undefined;
    this.sections.forEach((s, idx) => {
      if (s.name === field.section) {
        sIdx = idx;
      }
    });
    return sIdx;
  }

  updateCurrentFieldCustomHandleRequire(newVal, oldVal) {
    if (newVal === undefined || oldVal === undefined || this.isDragAction) {
      return false;
    }
    if (this.isClickForCurrentField) {
      this.resetClickOnField();
      return false;
    }
    return true;
  }

  getSections() {
    let sections = [];
    for (const fname in this.dynamicModule.sections) {
      const val = this.dynamicModule.sections[fname]
      val.name = fname
      sections.push(val)
    }
    sections = sections.sort((a, b) => { return a.order - b.order; })
    let i = 1
    sections.forEach(s => s.order = i++)
    return sections
  }

  @Watch("currentField.section")
  fieldSectionHandler(newVal, oldVal) {
    if (!this.updateCurrentFieldCustomHandleRequire(newVal, oldVal)) {
      return true;
    }
    const field = this.currentField;
    field['section'] = oldVal;
    this.deleteFieldFromList(oldVal, this.currentField['column'], field);
    field['section'] = newVal;
    this.emulateDrag(field);
  }

  @Watch("currentField.column")
  fieldColumnHandler(newVal, oldVal) {
    if (!this.updateCurrentFieldCustomHandleRequire(newVal, oldVal)) {
      return true;
    }
    const field = this.currentField;
    field['column'] = oldVal;
    this.deleteFieldFromList(field['section'], oldVal, field);
    field['column'] = newVal;
    this.emulateDrag(field);
  }

  @Watch("currentField.order")
  fieldOrderHandler(newVal, oldVal) {
    if (!this.updateCurrentFieldCustomHandleRequire(newVal, oldVal)) {
      return true;
    }
    const colName = this.getStringColumnVal(this.currentField);
    const sInd = this.getSectionIdxFromField(this.currentField);
    const elementToChange = this.fields[sInd][colName].find(el => el.order === newVal && el.ID !== this.currentField['ID'])
    if(elementToChange){
      elementToChange.order = oldVal
    }
  }
  
  @Watch("currentSection.name")
  sectionNameHandler(newVal) {
    if (this.isNewSectionSelect) {
      setTimeout(
        function (me) {
          me.isNewSectionSelect = false;
        },
        200,
        this
      );
      return true;
    }
    this.isClickForCurrentField = true;
    let sIdx = null;
    this.sections.forEach((sec, i) => {
      if (sec.name === newVal) {
        sIdx = i;
      }
    });
    for (const secCol in fieldsMapping.getEmptySections()) {
      this.fields[sIdx][secCol].forEach(
        (field) => (field.section = newVal)
      );
    }
    this.resetClickOnField();
  }

  @Watch("currentSection.order")
  sectionOrderHandler(newVal, oldVal) {
    if (this.isNewSectionSelect) {
      setTimeout(
        function (me) {
          me.isNewSectionSelect = false;
        },
        200,
        this
      );
      return true;
    }
    this.isClickForCurrentField = true;
    const newSIdx = newVal - 1;
    const oldSIdx = oldVal - 1;

    const newSectionFields = this.fields[newSIdx];
    this.fields[newSIdx] = this.fields[oldSIdx];
    this.fields[oldSIdx] = newSectionFields;

    const newSectionVal = this.sections[newSIdx];
    this.sections[newSIdx] = this.sections[oldSIdx];
    this.sections[oldSIdx] = newSectionVal;
    this.resetClickOnField();
  }

  get fields() {
    const sections = this.getSections()
    const fields = [];
    const fieldsGenerationsArray = []
    for (const fname in this.dynamicModule.fields) {
      const val = this.dynamicModule.fields[fname]
      val.name = fname
      fields.push(val)
    } //перебор и добавление поля
    fields.sort((a, b) => {
      return a.order - b.order;
    }) //отсортировали поля
    
    for (const sInd in sections) {
      const section = sections[sInd] //перебор секций
      for (const fname in fields) { 
        const val = fields[fname]
        if (val.section === section.name) {
          if (!fieldsGenerationsArray[sInd]) {
            fieldsGenerationsArray[sInd] = {}
          }
          const colName1 = this.getStringColumnVal({column: 1})
          if (!fieldsGenerationsArray[sInd][colName1]) {
            fieldsGenerationsArray[sInd][colName1] = []
          }

          const colName2 = this.getStringColumnVal({column: 2})
          if (!fieldsGenerationsArray[sInd][colName2]) {
            fieldsGenerationsArray[sInd][colName2] = []
          }

          const colName3 = this.getStringColumnVal({column: 3})
          if (!fieldsGenerationsArray[sInd][colName3]) {
            fieldsGenerationsArray[sInd][colName3] = []
          }
          const colName = fieldsMapping.getStringColumnVal(val)
          fieldsGenerationsArray[sInd][colName].push(val);
        } // добавлаяем поля и колонки в нужные секции 
      }

      for (const colName in fieldsGenerationsArray[sInd]) {  //перебор колонок
        fieldsGenerationsArray[sInd][colName].forEach((s) => {
        Object.keys(this.dynamicModule.dropDownValues).forEach((key) => {
          if(key === s.name){
            s.options = this.dynamicModule.dropDownValues[key]
          }
        })
          if (!s.iD) {
            s.ID = uid();
          }
        })
      }
    }
    return fieldsGenerationsArray
  }

  get sections() {
    return this.getSections()
  }

  get orderOptions() {
    const options = [];
    for (let i = 1; i <= this.currentListLength; i++) {
      options.push(i);
      }

    return options;
  }
}
